import styled from "styled-components";
import Radio from "../../student-dashboard/profile/common/radio";
const PageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #ffffff;
  max-width: 1400px;
  margin: 7px 30px 74px 30px;
  padding: 25px;
  box-shadow: 0 3px 3px 0 #c4c4c4;
`;
const PageHeader = styled.label`
  display: flex;
  width: 100%;
  font-size: 22px;
  color: #3e3d43;
  padding-bottom: 15px;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 1000px;
  line-height: 68px;
`;
const ProgressBarParentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 2px;
`;
const ProgressBarChildDiv = styled.div`
  width: ${(props) => (props.widthPer ? `${props.widthPer}` : "0")};
  background: #016738;
  max-width: 600px;
  display: flex;
  height: 4px;
  border-radius: 2px;
`;

const BtnBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Question = styled.div`
  width: 100%;
  display: flex;
  max-width: 586px;
  line-height: 25px;
  margin-top: 32px;
`;
const AnswerBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 20px 20px 20px;
`;
const Answers = styled.div`
  display: flex;
  width: 65%;
  max-width: 550px;
  flex-direction: column;
  line-height: 50px;
`;
const QuestionImg = styled.img`
  // width: 45%;
  // max-width: 320px;
  max-width: 400px;
  height: 100%;
  max-height: 250px;
`;
const AnswerPanel = styled.div`
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const RadioBtn = styled(Radio)`
  margin-right: 18px;
  width: 100%;
  max-width: 24px;
`;
const MobRadioBtn = styled(Radio)`
  width: 100%;
  width: 24px;
`;
const Answer = styled.div`
  background: #eeeeee;
  width: 100%;
  padding: 5px 20px;
`;
const Btn = styled.button`
  display: flex;
  max-width: 136px;
  justify-content: center;
  padding: 7px 25px;
  align-items: center;
  border-radius: 45px;
  background: #016738;
  color: #ffffff;
  border: 1px;
  font-weight: 900;
  margin-top: 25px;
  cursor: pointer;
  margin-right: 35px;
`;
const MobBtn = styled.button`
  display: flex;
  max-width: 136px;
  justify-content: center;
  padding: 7px 25px;
  align-items: center;
  border-radius: 45px;
  background: #016738;
  color: #ffffff;
  border: 1px;
  font-weight: 900;

  cursor: pointer;
  margin-right: 35px;
`;
const QuestionTrackBlock = styled.div`
  width: 100%;
  border-left: 0.7px solid #c4c4c4;
  flex-direction: column;
  display: flex;
  padding: 22px;
  max-width: 400px;
`;
const AnswerTrackBlock = styled.div`
  width: 100%;
  min-width: 162px;
  max-width: 180px;
  display: flex;
  align-items: center;
`;
const SpanText = styled.span`
  white-space: nowrap;
  font-size: 16px;
  margin-top: 7px;
`;
const RoundAnswer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  // background: ${(props) =>
    props.answerType ? `${props.answerType}` : AnswerTrack.NOT_VISITED_COLOR};
  background: ${(props) =>
    props.answerType ? `${props.answerType}` : AnswerTrack.NOT_VISITED_COLOR};
  margin: 5px 5px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;
const MobileRoundAnswer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  // background: ${(props) =>
    props.answerType ? `${props.answerType}` : AnswerTrack.NOT_VISITED_COLOR};
  background: ${(props) =>
    props.answerType ? `${props.answerType}` : AnswerTrack.NOT_VISITED_COLOR};
  margin: 5px 5px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;

const NumberOfQuestions = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  flex-wrap: wrap;
  gap: 20px 16px;
  margin-top: 20px;
  max-height: 325px;
  overflow: auto;
`;
const QuestionTracking = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 400px;
  gap: 40px 5px;
`;

let AnswerTrack = {
  ANSWERED_COLOR: "#5A974F",
  NOT_VISITED_COLOR: "#C4C4C4",
  NOT_ANSWERED_COLOR: "#DC0606",
};

export {
  PageBlock,
  Container,
  PageHeader,
  PageContainer,
  AnswerTrack,
  QuestionTracking,
  NumberOfQuestions,
  RoundAnswer,
  MobileRoundAnswer,
  SpanText,
  AnswerTrackBlock,
  QuestionTrackBlock,
  Btn,
  MobBtn,
  Answer,
  RadioBtn,
  MobRadioBtn,
  AnswerPanel,
  QuestionImg,
  Answers,
  AnswerBlock,
  Question,
  BtnBlock,
  ProgressBarChildDiv,
  ProgressBarParentDiv,
};
